import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

import GoogleImage from "./GoogleImage"
import { colors, devices } from "../settings"

const Video = styled.div`
  padding: 5px;
  background-color: white;

  h3 {
    margin: 0px 0px 8px 0px;
    font-size: 14px;
    font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  }
  > div {
    .date {
      font-size: 10px;
      color: #666;
      margin-bottom: 10px;
    }
    .content {
      font-size: 12px;
    }
  }

  cursor: pointer;

  img {
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.025);
      box-shadow: 0 10px 10px rgba(0,0,0,0.3);
    }
  }
`
const VideoImg = styled.div`
  flex: 0;
  flex-basis: 40%;

  a {
    display: block;
    position: relative;

    svg {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: .7;

      @media ${devices.mobile} {
       width: .5em !important;
      }
    }  
  }

  &:hover {
    svg {
      color: ${colors.orange};
      opacity: 1;
    }
  }
`

function VideoPreview(props) {
  return (
    <>
      <Video className="video">
        <VideoImg>
            <Link to={props.page_url}>
                { props.image_url && 
                  <GoogleImage 
                      root 
                      required 
                      lazy
                      src={props.image_url} 
                      alt={props.title} 
                      style={{
                          width: "100%"
                      }}
                  />
                }
                { props.image_src && 
                  <img 
                      src={props.image_src} 
                      loading="lazy"
                      alt={props.title} 
                      style={{
                          width: "100%"
                      }}
                  />
                }
                <FontAwesomeSvgIcon icon={faPlayCircle} size={props.size && props.size == "large" ? "6x":"3x"} />
            </Link>
        </VideoImg>
        <div>
            {props.title && <h3>{props.title}</h3>}
            {props.summary && <div className="content" dangerouslySetInnerHTML={{__html: props.summary}} />}
        </div>
      </Video>
    </>
  );
}

export default VideoPreview