import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons"
import produce from "immer"
import { isMobile } from "react-device-detect";

import Page from "../components/layout/Page"
import PageHeader from "../components/layout/PageHeader";
import SEO from "../components/seo"
import Layout2Columns from "../components/layout/Layout2Columns"
import GoogleImage from "../components/GoogleImage"
import Button from "../components/buttons/HoverButton"
import MajorError from "../components/MajorError"
import SelectField from "../components/fields/SelectField"
import VideoPreview from "../components/VideoPreview"

import { CartContext } from '../state/CartContext';
import { devices, colors } from "../settings"

const Content = styled.div`
    margin-left: 25px;

    > p, > div {
        margin-left: 20px;        
    }

    ul {
        list-style-type: none;
    }

    @media ${devices.mobile} {
        margin-left: 0px;

        > p, > div {
            margin-left: 0px;        
        }

        ul {
            padding-left: 0px;
        }
    } 
`
const Sizes = styled.ul`
    li {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    
        select {
          min-width: 60px;
          margin: 0px;
          padding: 0px;
        }

        .cost {
            color: ${colors.green};
            width: 60px;
            text-align: right;
        }        
    }

    @media ${devices.mobile} {
        li {
            flex-flow: wrap;
        }
    }    
`

export default function RoofBrackets({data}) {
    const cart = useContext(CartContext);

    const product = data.products.edges.length ? data.products.edges[0].product : null;
    const accessory = data.accessories.edges[0].accessory;
    const sizes = data.sizes.edges;

    const [quantity, setQuantity] = useState({})
    const [color, setColor] = useState({})
    const [adding, setAdding] = useState(null);
    const [error, setError] = useState(null);

    useEffect(
        () => {
            let values = {}

            for (const size of sizes) {
                values["size" + size.size.size_id] = 2;
            }
            
            setQuantity(values)
        }, 
        []
    );

    function addAccessory(accessorySize) {
        setAdding(accessorySize.id);

        cart.addAccessoryToCart(
            accessorySize.size_id, 
            quantity["size" + accessorySize.size_id] || 1, 
            color["size" + accessorySize.size_id] || null, 
            (cart) => {
            if (cart) {
                navigate("/checkout/")
            }
            else {
                setError("An error occured adding the accessory to your cart.")
            }

            setAdding(null);
        })
    }

    const quantities = [1,2,3,4,5,6,7,8,9,10]

    let breadcrumb = [
        {title: "Home", url:"/"},
        {title: "Products", url:"/products/"},                    
    ]

    if (product) {
        breadcrumb.push({title: product.category, url: "/products/" + product.category_name + "/"});
        breadcrumb.push({title: product.title, url: "/products/" + product.url + "/"});
    }
    else {
        breadcrumb.push({title: "Accessories", url:"/products/accessories/"});
    }

    breadcrumb.push({title: accessory.title, url: null});

    return (
        <>
            <SEO page="accessory" title={accessory.title} />

            <Page breadcrumb={breadcrumb}>
                    <PageHeader title={accessory.title} />
                    <Content>
                        <h2>When to use our Roof Mount Bracket</h2>
                        <p>Roof mount brackets are for mounting your Raceway Mounted Signs, Lit shape Signs or Lit Sign Cabinets signs to a sloped roof. Our brackets are field adjustable to whatever your roof angle, from 3/12 to 12/12 pitch. Simply install the adjustment screw at whatever pitch makes your sign in the correct vertical position. Our Brackets are designed to be used as multiples. They are not designed to be used individually.</p>
                        <p>Care should be taken to seal roof penetrations. Frame is only as strong as its mounting surface. We recommend through bolting through roof with wood or steel backing plate. Installer is required to determine if the roof can support the sign installation. Customer is responsible to seal the roof around the bracket to prevent leaking.</p>

                        <h2>Choosing the correct Size Roof Mount Bracket</h2>
                        <p>On each type of sign that is mounted using the Roof mount Brackets, it is important to leave room under your sign for snow, leaves and water to be under your sign and not cause damage to the sign. This will require at least 4" to 8" of space below the sign, above the roof. Our Brackets are not designed for the sign to be mounted more than 16" form the bottom of the sign to the roof.</p>
                        <p>Contact us if you need help determining the size of the Roof Mount Sign Bracket.</p>

                        <ul>
                            <li>
                                <h3>Raceway Mounted Signs</h3>
                                Raceway Mounted signs the bracket will attach to the top and bottom of the raceway. This will in most cases be below the top of the letters or logos. For Roof Mounted Raceway signs you will need a minimum of two brackets, for a sign under 6' in length. We suggest an additional Sign Bracket every 3 feet of raceway length.
                            </li>
                            <li>
                                <h3>Lit Shape Signs</h3>
                                Bracket will attach to the top and bottom of the Lit Shape. When the Lit Shape Sign is ordered together with the Roof Mounting Brackets, the sign will include mounting points for the brackets when you receive it. Each Lit Shape Sign will need minimum of two brackets, for a sign under 6' in width. We suggest an additional Sign Bracket every 3 feet of sign width.
                            </li>
                            <li>
                                <h3>Lit Sign Cabinets</h3>
                                Bracket will attach to the top and bottom of the cabinet. When the Lit Sign Cabinet is ordered together with the Roof Mounting Brackets, the sign will include mounting points when you receive it. Each Lit Cabinet Sign will need minimum of two brackets, for a sign under 6' in length. We suggest an additional Sign Bracket every 3 feet of sign width.
                            </li>
                        </ul>

                        <br />

                        <Layout2Columns align="top" left={35} right={65}>
                            <div>
                                <GoogleImage root src={accessory.image} required lazy alt={accessory.title} width={isMobile ? 355:396} height={isMobile ? 221:247} />
                            
                                <VideoPreview
                                    video_url="https://youtu.be/yIix1NDMGss"
                                    image_url="files/761328EA-964A-C068-5A71-4D81509D7B79.jpg"
                                    page_url="/why/videos/roof-mount-angle-bracket"
                                    size="large"
                                />
                            </div>
                            <div>
                                <Sizes>
                                    {sizes.map(({size}) => (
                                        <li key={size.id}>
                                            <span dangerouslySetInnerHTML={{__html: size.size}} />
                                            &nbsp;                      
                                            <span className="cost">${size.cost}</span>
                                            &nbsp;
                                            {' '}Each
                                            &nbsp;
                                            &nbsp;
                                            <div>
                                                <div style={{display: "flex"}}>
                                                    <SelectField 
                                                        label="Quantity"
                                                        value={quantity["size" + size.size_id]}
                                                        options={
                                                            quantities.map((quantity, index) => {
                                                                return {
                                                                    value: quantity,
                                                                    label: quantity
                                                                }
                                                            })
                                                        }
                                                        onChange={(e) => {
                                                            e.persist();

                                                            setQuantity(produce(draft => {
                                                                draft["size" + size.size_id] = e.target.value;
                                                            }));
                                                        }}
                                                    />
                                                    <SelectField 
                                                        label="Color"
                                                        value={color["size" + size.size_id]}
                                                        required
                                                        options={[
                                                            {value: "Black", label: "Black"},
                                                            {value: "White", label: "White"},
                                                            {value: "Metallic Silver", label: "Metallic Silver"},
                                                            {value: "Red", label: "Red"},
                                                            {value: "Green", label: "Green"},
                                                            {value: "Duranodic Bronze", label: "Duranodic Bronze"},
                                                            {value: "To Be Determined", label: "Other"},
                                                        ]}
                                                        onChange={(e) => {
                                                            e.persist();

                                                            setColor(produce(draft => {
                                                                draft["size" + size.size_id] = e.target.value;
                                                            }));
                                                        }}
                                                    /> 
                                                </div> 

                                                {quantity["size" + size.size_id] == 1 && 
                                                    <div>
                                                        <small style={{marginLeft: "8px", color: "red"}}>
                                                            Must be used in multiples, cannot be used alone.
                                                        </small>
                                                    </div>
                                                }                                                  
                                            </div>                                                                                    
                                            &nbsp;
                                            <Button 
                                                label="Add To Cart"
                                                color="green"
                                                icon={faShoppingCart}
                                                busy={adding == size.id}
                                                disabled={adding == size.id}
                                                onClick={() => addAccessory(size)}
                                            />                                              
                                        </li>
                                    ))}
                                </Sizes>
                            </div>
                        </Layout2Columns>
                    </Content>
                    
                    { error &&
                        <MajorError 
                            error={error} 
                            log={error}
                            onClose={() => setError(null)}
                        />
                    }
            </Page>
        </>
    )
}

export const query = graphql`
    query($productName: String!, $name: String!) {
        products: allMysqlProducts(filter: {name: {eq: $productName}}) {
            edges {
                product: node {
                    id
                    name
                    url
                    title
                    category
                }
            }
        }
        accessories: allMysqlAccesories(filter: {name: {eq: $name}}) {
            edges {
                accessory: node {
                    id
                    name
                    title
                    image
                }
            }
        }
        sizes: allMysqlAccesorySizes(filter: {name: {eq: $name}}) {
            edges {
              size: node {
                id
                size_id
                accessory_id
                size
                cost
              }
            }
        }
    }`